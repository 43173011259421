import React from "react";
import { carousel2 } from "../images/index.js";
const Policies = () => {
  return (
    <div className="my-16 mx-auto w-11/12 lg:w-10/12 grid md:grid-cols-3 grid-cols-1">
      <div>
        <img className="h-144 object-cover " src={carousel2} alt="..." />
        <div className="mt-3">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </div>
      </div>
      <div className="col-span-2 ml-16 mt-16">
        <div className="text-5xl">Policies</div>
        <div className="mt-16">
          <ul className="list-disc">
            <li>
              tempor incididunt ut labore et dolore magna aliqua. Ut enim
              adminim veniam,
            </li>
            <li>
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo
            </li>
            <li>
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse
            </li>
            <li>
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non
            </li>
            <li>
              proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum.
            </li>
            <li>
              tempor incididunt ut labore et dolore magna aliqua. Ut enim
              adminim veniam,
            </li>
            <li>
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo
            </li>
            <li>
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse
            </li>
            <li>
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non
            </li>
            <li>
              proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Policies;
