import { Fragment } from "react";
import "./App.css";
import Navbar from "./component/Navbar.js";
import Hero from "./component/Hero.js";
import TheSpace from "./component/TheSpace.js";
import Carousel from "./component/Carousel.js";
import Events from "./component/Events.js";
import Policies from "./component/Policies.js";

function App() {
  return (
    <Fragment>
      <Navbar />
      <Hero />
      <TheSpace />
      <Carousel />
      <Events />
      <Policies />
    </Fragment>
  );
}

export default App;
