import React, { Component } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Swipe from "react-easy-swipe";
import { carousel1, carousel2, carousel3 } from "../images/index.js";

const CarouselData = [
  {
    image: carousel1,
  },
  {
    image: carousel2,
  },
  {
    image: carousel3,
  },
];

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      paused: false,
    };
  }
  // automatically slide every 3 sec
  // componentDidMount() {
  //   setInterval(() => {
  //     if (this.state.paused === false) {
  //       let newSlide =
  //         this.state.currentSlide === CarouselData.length - 1
  //           ? 0
  //           : this.state.currentSlide + 1;
  //       this.setState({ currentSlide: newSlide });
  //     }
  //   }, 3000);
  // }

  nextSlide = () => {
    let newSlide =
      this.state.currentSlide === CarouselData.length - 1
        ? 0
        : this.state.currentSlide + 1;
    this.setState({ currentSlide: newSlide });
  };

  prevSlide = () => {
    let newSlide =
      this.state.currentSlide === 0
        ? CarouselData.length - 1
        : this.state.currentSlide - 1;
    this.setState({ currentSlide: newSlide });
  };

  setCurrentSlide = (index) => {
    this.setState({ currentSlide: index });
  };

  render() {
    return (
      <div className=" my-16 relative  bg-white1  flex items-center justify-center">
        {/* arrows  */}

        <div className="absolute w-11/12 lg:w-8/12 flex justify-between items-center">
          <AiOutlineLeft
            onClick={this.prevSlide}
            className="  text-4xl bg-white1 bg-opacity-50  text-brown1 cursor-pointer"
          />

          <AiOutlineRight
            onClick={this.nextSlide}
            className="text-4xl bg-white1 bg-opacity-50 text-brown1 cursor-pointer"
          />
        </div>

        {/* main image  */}

        <div className="overflow-hidden lg:w-auto flex items-center justify-center">
          <Swipe onSwipeLeft={this.nextSlide} onSwipeRight={this.prevSlide}>
            {CarouselData.map((slide, index) => {
              return (
                <img
                  src={slide.image}
                  alt="This is a carousel slide"
                  key={index}
                  className={
                    index === this.state.currentSlide
                      ? "block  lg:h-144 "
                      : "hidden"
                  }
                  // this goes together with componentDidMount()
                  // onMouseEnter={() => {
                  //   this.setState({ paused: true });
                  // }}
                  // onMouseLeave={() => {
                  //   this.setState({ paused: false });
                  // }}
                />
              );
            })}
          </Swipe>
        </div>

        {/* image indicator  */}

        <div className="absolute flex p-2 bottom-5 bg-brown1 rounded-3xl bg-opacity-50 overflow-hidden ">
          {CarouselData.map((element, index) => {
            return (
              <div
                className={
                  index === this.state.currentSlide
                    ? "h-2 w-2 bg-white1 rounded-full mx-2 cursor-pointer"
                    : "h-2 w-2 bg-black rounded-full mx-2 cursor-pointer"
                }
                key={index}
                onClick={() => {
                  this.setCurrentSlide(index);
                }}
              ></div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Carousel;
