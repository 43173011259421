import React, { Fragment } from "react";
import { useState } from "react";
import { logo } from "../images";

const Navbar = () => {
  const [showMobile, setShowMobile] = useState(false);

  return (
    <Fragment>
      <nav className="bg-white3 text-md fixed z-50 top-0">
        {/* <div className="w-9/12 mx-auto text-brown1"> */}
        <div className="w-screen mx-auto text-brown1">
          <div className="flex justify-between">
            {/* logo */}
            <div className="flex">
              <a
                className="flex items-center justify-start mx-10 py-3"
                href="/#"
              >
                {/* <span>Miles' Playplace</span> */}
                Miles' Playplace
                <img className="w-10 ml-3" src={logo} alt="..." />
              </a>
            </div>
            {/* primary nav */}
            <div className="hidden lg:flex items-center justify-end space-x-3">
              <a className="px-2 hover:text-black py-4" href="/#">
                THE SPACE
              </a>
              <a className="px-2 hover:text-black py-4" href="/#">
                EVENTS
              </a>
              <a className="px-2 hover:text-black py-4" href="/#">
                MEMBERSHIPS
              </a>
              <a className="px-2 hover:text-black py-4" href="/#">
                POLICIES
              </a>
              <a className="px-2 hover:text-black py-4" href="/#">
                LOCATION
              </a>
              <a className="px-2 hover:text-black py-4" href="/#">
                ABOUT US
              </a>
              <a className="px-2 hover:text-black py-4" href="/#">
                CONTACT
              </a>
              <a
                className="bg-brown1 text-white2 py-4 px-6 text-center hover:text-black"
                href="/#"
              >
                MEMBERSHIP
                <br />
                PROCESS
              </a>
            </div>

            {/* mobile buttons */}
            <div className="lg:hidden flex items-center px-10">
              <button onClick={() => setShowMobile(!showMobile)}>
                {showMobile ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* mobile nav  */}
        <div
          className={`${
            showMobile
              ? "h-screen text-center text-3xl mt-10 text-brown1 space-y-5 lg:hidden"
              : "hidden"
          }`}
          onClick={() => setShowMobile(!showMobile)}
        >
          <a className="block py-2 px-4 hover:text-black" href="/#">
            ABOUT US
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            THE SPACE
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            EVENTS
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            MEMBERSHIPS
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            POLICIES
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            LOCATION
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            CONTACT
          </a>
          <a className="block py-2 px-4 hover:text-black" href="/#">
            MEMBERSHIP PROCESS
          </a>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
