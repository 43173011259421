import React, { Fragment } from "react";

const Hero = () => {
  return (
    <Fragment>
      <div className="relative my-16">
        <div className="bg-hero bg-cover bg-center  bg-no-repeat h-150 text-white1 ">
          <div className=" w-full h-full backdrop-brightness-50 flex flex-col items-center justify-end ">
            {/* hero title  */}
            <div className="text-center">
              <div className="text-7xl">Miles' Playplace</div>
              <div className="w-10/12 m-auto text-3xl">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod
              </div>
              <div className="mt-28">
                <a href="/#" className="text-brown1 bg-white1 text-xl p-10">
                  BECOME A MEMBER TODAY
                </a>
              </div>
            </div>

            {/* address  */}
            <div className="w-full text-start mt-20 text-3xl">
              457 Adelaide Way, Toronto <br /> Monday - Sunday
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Hero;
